import React, { useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  borderRadius: "16px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  p: 4,
  outline: "none",
  "& .MuiTypography-h6": {
    color: "#4a287d",
    fontWeight: 600,
    textAlign: "center",
    mb: 3,
  },
};

const listItemStyle = {
  bgcolor: "#f5f5f5",
  borderRadius: "8px",
  mb: 1,
  transition: "all 0.2s ease",
  "&:hover": {
    bgcolor: "#f6f0fe",
    transform: "translateX(5px)",
  },
};

interface IMSFormData {
  [key: string]: string | null;
}

interface FormModalProps {
  imsFormData: IMSFormData;
  onOpen: boolean;
  onClose: () => void;
}

const RequiredFieldsModal: React.FC<FormModalProps> = ({
  imsFormData,
  onOpen,
  onClose,
}) => {
  // Changed the filter logic to find empty fields
  const emptyFields = Object.entries(imsFormData)
    .filter(([_, value]) => {
      return (
        value === null ||
        value === undefined ||
        value === "" ||
        value === "0" ||
        value?.trim() === ""
      );
    })
    .map(([key]) => key);

  useEffect(() => {
    console.log("REQUIRED FIELDS==============>", emptyFields);
  }, [emptyFields]);

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
      }}
    >
      <Box sx={modalStyle}>
        <Typography variant="h6">
          Hello! To proceed, fill out the fields below.
        </Typography>
        <List sx={{ mt: 2 }}>
          {emptyFields.map((field) => (
            <ListItem key={field} sx={listItemStyle}>
              <ListItemIcon>
                <ErrorOutlineIcon sx={{ color: "#f44336" }} />
              </ListItemIcon>
              <ListItemText
                primary={field}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontWeight: 500,
                    color: "#37474f",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default RequiredFieldsModal;
